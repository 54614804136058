<template>
  <v-system-bar height="50" color="transparent" dark>
    <v-spacer></v-spacer>
    <span>queue: {{ queue_size }}</span>
    <v-spacer></v-spacer>
    <v-icon>mdi-wifi-strength-4</v-icon>
    <v-icon>mdi-signal-cellular-outline</v-icon>
    <v-icon>mdi-battery</v-icon>
  </v-system-bar>
</template>

<script>
import { get_system_info, } from "@/api";

export default {
  name: "StatusBar",

  destroyed() {
    clearInterval(this.timer);
  },

  mounted: async function () {
    this.updateSystemInfo();
    this.timer = setInterval(() => {
      this.updateSystemInfo();
    }, 5000);

  },

  watch: {},

  data() {
    return {
      timer: -1,
      queue_size: 0,
    };
  },

  methods: {
    updateSystemInfo() {
      get_system_info().then((systemInfo) => {
        this.queue_size = systemInfo.queue_size;
      }).catch((reason) => {
        this.queue_size = 0;
      });
    },
  },
};
</script>
